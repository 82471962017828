import React from "react"
import { WarningContainer } from "./style"
import { MainColumn } from "components/MainColumn"

const WarningModule = ({message}) => {
  return (
    <WarningContainer>
      <MainColumn>
        <div dangerouslySetInnerHTML={{
            __html: message}} />
      </MainColumn>
    </WarningContainer>
  )
}

export default WarningModule
