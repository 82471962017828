import styled from "styled-components"
import { device } from "breakpoints"

export const WarningContainer = styled.div`
  background: #d3ecee;
  padding: 10px 0;
  margin-bottom: 40px;

  @media ${device.desktop} {
    padding: 20px 0;
    margin-bottom: 64px;
  }

  p {
    font-size: 16px;

    @media ${device.desktop} {
      font-size: 18px;
      width: 65%;
    }

    span {
      font-weight: 600;
    }
  }
`
