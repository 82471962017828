import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { InternalPageWrapper } from "components/globalStyles"
import { InternalHeader } from "../components/InternalHeader"
import CTAModule from "../components/CTAModule/CTAModule"
import { InternalForm } from "../components/InternalForm"
import { WarningModule } from "../components/WarningModule"
import { MainColumn } from "components/MainColumn"

const BotPage = ({ pageContext }) => {
  const { title, pageSettings, content, botSettings } = pageContext

  return (
    <Layout pageContext={pageContext}>
      <SEO title={title} />
      <InternalPageWrapper>
        <InternalHeader
          title={title}
          headerDescription={pageSettings.pageHeaderDescription}
          color="blue"
          noLine
        />
      </InternalPageWrapper>
      <WarningModule message={botSettings.stripMessage} />
      <MainColumn>
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </MainColumn>

      {pageSettings?.ctaBlock?.title && (
        <CTAModule pageColor="blue" data={pageSettings.ctaBlock} />
      )}
      {pageSettings.feedbackQuestionText && (
        <InternalForm
          page={title}
          introText={pageSettings.feedbackQuestionText}
        />
      )}
    </Layout>
  )
}

export default BotPage
